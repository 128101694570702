"use strict";

import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/toast";

export const refresh_basket_compact = function(data) {
    let basket_inner = $(data.basket_compact_html).html();
    $('.basket-compact').html(basket_inner);

    let basketQuantity = $('.header-basket-quantity');

    if (data.basket.num_items === 0) {
        basketQuantity.empty().addClass('d-none');
    } else {
        basketQuantity.html(data.basket.num_items).removeClass('d-none');
    }
}

export const refresh_basket = function() {
    // Only do this if the formset already has items in it
    if ($('#basket_formset').length > 0) {
        window.location.reload();
    }
};

const convertFormDataToJson = function(form){
    const array = jQuery(form).serializeArray();
    let json = {};

    jQuery.each(array, function() {
        json[this.name] = this.value;
    });

    return json;
};

$(function() {
    var displayMessage = function(alertContextName, title, body) {
        var messageModalHtml = [
            '<div id="message-modal" class="modal fade">',
                '<div class="modal-dialog" role="document">',
                    '<div class="modal-content">',
                        `<div class="modal-header alert alert-${ alertContextName }">`,
                            `<h4 class="modal-title">${ title }</h4>`,
                        '</div>',
                        '<div class="modal-body">',
                            `${ body }`,
                            '<button type="button" onClick="window.location.reload()" class="btn btn-warning">Reload</button>',
                        '</div>',
                    '</div>',
                '</div>',
            '</div>'
        ];

        var messageModal = $(messageModalHtml.join(''));
        messageModal.modal({backdrop: 'static'});
    };

    var displayErrorMessage = function(jqXHR, textStatus, errorThrown) {
        displayMessage(
            'danger',
            "Something went wrong.",
            `<p>There was an error modifying your cart. Please reload the page and try again.</p>
             <p>If the problem persists, please check that cookies are enabled in your web browser.</p>`);
     };

    $('.js-basket-add-form').on('submit', function(e){
        e.preventDefault();
        const form = $(this);
        const button = form.find(':submit');
        const originalButtonText = button.text();
        const originalButtonClass = button.attr('class');

        function resetUI() {
            button.html(originalButtonText);
            button.attr('disabled', false);
            button.attr('class', originalButtonClass);
        }
        const form_api_action = form.data('api-action');
        const form_data = convertFormDataToJson(form);
        const product_options = []

        form.find('[data-option]').each(function(index){
            var elem = $(this);
            var optionId = elem.data('option');
            var value = elem.val();

            if(isNaN(optionId) || !value){
                return;
            }

            product_options.push({
                'option': optionId,
                'value': value
            });
        });

        form_data['options'] = product_options

        $.ajax({
            url: form_api_action,
            type: "POST",
            data: JSON.stringify(form_data),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: function(data, status, config){
                if (data.basket_is_invalid) {
                    displayMessage(
                        'warning',
                        "Problem adding products to basket.",
                        `<p>The product${ data.product_quantity > 1 ? "s" : "" } could not be added to the basket: ${ data.reason }</p>`);
                    return;
                }
                resetUI();
                refresh_basket_compact(data);

                var event = {type: 'basket_modified', data: data};
                $(document).trigger('broadcast', {event: event});

                if(data.cart_modal_html){
                    $(data.cart_modal_html).modal();
                }else if(data.toast_html){
                    var toast_element = $(data.toast_html);
                    $('#toast-container').append(toast_element);
                    toast_element.toast({
                        delay: 5000
                    });
                    toast_element.toast('show');
                }

                $(document).trigger('basket_add_form_submitted', {
                    'ga_events': data.ga_events,
                    'fbp_events': data.fbp_events,
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                resetUI();
                displayErrorMessage(jqXHR, textStatus, errorThrown);
            },
        });

        return false;
    });

    $('.basket-compact').on('click', '.basket-line-remove button', function(e){
        e.preventDefault();
        e.stopPropagation();
        var el = $(this),
            api_url = el.data('api-url'),
            line_pk = el.data('line'),
            $basket_el = $(e.delegateTarget);

        $.post(api_url,{
                line: line_pk,
                csrfmiddlewaretoken: $basket_el.data('csrf-token'),
        })
        .done(function(data, status, config){
            refresh_basket_compact(data);

            var event = {type: 'basket_modified', data: data};
            $(document).trigger('broadcast', {event: event});

            $(document).trigger('compact_basket_line_removed', {
                'ga_events': data.ga_events,
            });

            refresh_basket();
        })
        .fail(displayErrorMessage);
    });

    $(document).on('basket_modified', function(e, data) {
        refresh_basket_compact(data);
    });
});
